import * as React from "react"
import TextCard from "./TextCard"

export default function TextCards(props) {
  const { view, data } = props
  const val = data?.val

  return (
    <div className="textCard__cont">
      {val?.map((item, index) => (
        <TextCard
          cardNumber={index}
          view={view}
          title={item.title}
          subTitle={item.subTitle}
          key={index}
          text={item.text}
          links={item.links}
        />
      ))}
    </div>
  )
}
