import * as React from "react"
import { motion } from "framer-motion"
import { SCREEN_WIDTH_DESKTOP } from "../../consts/SizeScreens"

export default function MediaPopup(props) {
  const { open, setOpen, children, classAdd } = props

  open
    ? (document.body.style.overflowY = "hidden")
    : (document.body.style.overflowY = "auto")

  function togglePopup() {
    !open
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto")
    setOpen(!open)
  }

  return (
    <div
      className={
        classAdd ? `${classAdd} backdropMediaPopup` : "backdropMediaPopup"
      }
    >
      {window.visualViewport.width > SCREEN_WIDTH_DESKTOP && (
        <motion.div
          onClick={togglePopup}
          className="backgroundMediaPopup"
          initial={{
            opacity: 0,
            backgroundColor: "none",
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.4,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.4,
            },
          }}
          transition={{ duration: 0.4 }}
        ></motion.div>
      )}

      <motion.div
        className="mediaPopup__modal"
        onClick={e => e.stopPropagation()}
        initial={{
          y: "100vh",
          // scale: scaleVar(),
        }}
        animate={{
          y: "0",
          opacity: 1,
          // scale: scaleVar(),
          transition: {
            duration: 0.4,
          },
        }}
        exit={{
          y: "100vh",
          transition: {
            duration: 0.4,
          },
        }}
        drag={"y"}
        dragConstraints={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = velocity.y || offset.y
          if (swipe > 225 || swipe < -225) setOpen(!open)
        }}
        transition={{ duration: 1 }}
      >
        {window.visualViewport.width < SCREEN_WIDTH_DESKTOP && (
          <motion.div
            onClick={togglePopup}
            className="backgroundMediaPopup"
            initial={{
              y: "100vh",
              backgroundColor: "none",
            }}
            animate={{
              y: "0",
              transition: {
                duration: 0.4,
              },
            }}
            exit={{
              y: "100vh",
              transition: {
                duration: 0.4,
              },
            }}
            transition={{ duration: 0.4 }}
          ></motion.div>
        )}

        <motion.button
          className="closeButton"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.8,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.1,
            },
          }}
          transition={{ duration: 1.2 }}
          onClick={togglePopup}
        ></motion.button>

        <div className="mediaPopup__modal-content">{children}</div>
      </motion.div>
    </div>
  )
}
