import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import Loader from "../Loader"
import MediaPopup from "./MediaPopup"

export default function VideoPopup(props) {
  const { videoSrc, open, setOpen, setVideoSrc } = props
  const ref = useRef(null)
  const [loading, setLoading] = useState(false)
  const [playerState, setPlayerState] = useState(false)

  const toggleVideo = isOpen => {
    ref.current.pause()
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      ref.current.srcObject = null
    }
    setOpen(isOpen)
  }

  const playVideo = () => {
    ref.current.play()
    setPlayerState(true)
  }

  useEffect(() => {
    const video = ref.current

    const metaDataEvent = () => {
      setLoading(true)
    }

    video.addEventListener("onloadeddata", metaDataEvent)

    const pauseVideo = e => {
      setPlayerState(false)
    }

    video.addEventListener("pause", pauseVideo)

    return () => {
      video.removeEventListener("pause", pauseVideo)
      video.removeEventListener("onloadeddata", metaDataEvent)
    }
  }, [])

  return (
    <MediaPopup
      setVideoSrc={setVideoSrc}
      open={open}
      setOpen={toggleVideo}
      classAdd={"videoPopup"}
      backgroundBlack={true}
    >
      <div className="videoPopup__cont">
        {!loading && <Loader />}

        <motion.video
          style={{
            opacity: loading ? 1 : 0,
            position: loading ? "relative" : "absolute",
            zIndex: playerState ? 11 : 1,
          }}
          ref={ref}
          autoPlay
          controls
          onPause={() => setPlayerState(false)}
          onPlay={() => setPlayerState(true)}
          transition={{ duration: 1 }}
          onLoadedData={() => setLoading(true)}
        >
          <source src={videoSrc} type="video/mp4" />
        </motion.video>
        {loading && !playerState && (
          <button
            className="button-play"
            style={playerState ? { zIndex: -1 } : { zIndex: 10 }}
            onClick={playVideo}
            aria-label="play video"
          >
            <span />
          </button>
        )}
      </div>
    </MediaPopup>
  )
}
