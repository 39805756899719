import * as React from "react"
import "./_doubleBlockHead.scss"

export default function DoubleBlockHead(props) {
    const {data, setFormPopup} = props

    return (
        <div className="doubleBlockHead__cont">
            <div className="doubleBlockHead__text">
                <h2>
                    {data?.title
                        ? data.title
                        : "Оставь заявку на бесплатный пробный урок"}
                </h2>
                <p>
                    {data?.subtitle
                        ? data.subtitle
                        : "Подробно ознакомишься с нюансами обучения. Познакомишься со школой, пообщаешься с нашими\n" +
                        "                            преподавателям. Ответим на любые вопросы о диджеинге."}
                </p>
            </div>
            <button
                className="button"
                aria-label={"Кнопка для открытия формы, чтобы оставить заявку"}
                onClick={() => setFormPopup(true)}
            >
                <span className="flare"></span>
                {data?.button ? data.button : "Оставить заявку"}
            </button>
        </div>
    )
}
