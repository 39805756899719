import * as React from "react"
import { motion } from "framer-motion"
import Img from "react-cool-img"
import DraggableCards from "./DraggableCards"
import { STRAPI_API } from "../../consts/api"

export default function StudentReviews(props) {
  const { data, setVideoSrc, setPopUpVideo, classAdd } = props

  return (
    <div className={classAdd}>
      <DraggableCards>
        {data.map((item, index) => {
          return (
            item?.video?.url && (
              <motion.div
                key={index}
                className="studentReviews__item"
                whileHover={{ scale: 1.05 }}
              >
                <motion.div
                  whileTap={{ scale: 1.05 }}
                  className="studentReviews__box"
                  onClick={() => {
                    setVideoSrc(STRAPI_API + item?.video?.url)
                    setPopUpVideo(true)
                  }}
                >
                  {item?.coverImg?.url && (
                    <div className="studentReviews__img">
                      <Img
                        draggable={false}
                        src={
                          STRAPI_API +
                          item.coverImg.url +
                          "?format=webp&height=470"
                        }
                        alt="Видео отзыв о Piooner dj школе"
                      />
                    </div>
                  )}

                  <span className="studentReviews__name">
                    {item.studentName}
                  </span>
                </motion.div>
              </motion.div>
            )
          )
        })}
      </DraggableCards>
    </div>
  )
}
