import * as React from "react"
import Img from "react-cool-img"

import VideoBoxImg from "../../static/media/video-box.webp"

// setVideoSrc, setPopUpVideo стейты для видимости и пути попапа
// videoSrc - путь до видео
export default function VideoBox(props) {
  const { setVideoSrc, setPopUpVideo, videSrc, coverImg } = props

  return (
    <div className="videoBox">
      <div className="videoBox__box">
        <Img
          className="videoBox__img"
          src={coverImg ? coverImg : VideoBoxImg}
          alt="img"
        />
        <div
          className="button-play"
          role="button"
          aria-label="play video"
          onClick={() => {
            setVideoSrc(videSrc)
            setPopUpVideo(true)
          }}
        >
          <span />
        </div>
      </div>
    </div>
  )
}
