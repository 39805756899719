import * as React from "react"
import TextCards from "./TextCards"

// classAdd - can be: textCard--two-one, textCard--one-two,
// textCard--simple, textCard--bigText,
// textCard--two, textCard--three, textCard--one

// data{title, subtitle, text}[] :
// view определяет как будет выглядить карточка, допустимые значения:
// view = numbering, если карточки пронумерованы, check - если содержит иконку check

export default function TextGlassCards(props) {
  const { classAdd, view, data } = props

  const textCardsData = data => {
    const textData = { val: [] }

    for (let item of [...Object.values(data)]) {
      const obj = {}
      if (item?.title) obj.title = item.title
      if (item?.subtitle) obj.subTitle = item.subtitle
      if (item?.text) obj.text = item.text
      if (item?.links) obj.links = item.links
      if (Object.keys(obj).length !== 0) textData.val.push(obj)
    }
    return textData
  }

  return (
    <div className={classAdd ? `${classAdd} textCardGlass` : "textCardGlass"}>
      <TextCards view={view} data={textCardsData(data)} />
    </div>
  )
}
