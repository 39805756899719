import * as React from "react"
import { Link } from "gatsby"

export default function TextCard(props) {
  const { cardNumber, view, title, subTitle, text, links } = props
  return (
    <div className="textCard__item">
      <div className="textCard__box">
        {view === "check" && <span className="textCard-check"></span>}
        {view === "numbering" && (
          <span className="textCard-numbering">{cardNumber + 1}</span>
        )}

        <span className="textCard-title">{title}</span>

        {subTitle && <span className="textCard-subtitle"> {subTitle}</span>}

        {text && (
          <div className="textCard-text">
            <p>{text}</p>
          </div>
        )}
        {links && (
          <div className="textCard-linkBlock">
            {links.map((item, i) => (
              <Link key={i} className="textCard-link" to={item.link}>
                {item.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
