import * as React from "react"
import { useState } from "react"
import { AnimatePresence } from "framer-motion"
import "./about/family/familyPage.scss"
import { graphql } from "gatsby"

import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout"
import DoubleBlockHead from "../components/offers/DoubleBlockHead/DoubleBlockHead"
import Title from "../components/Title"
import TextGlassCards from "../components/cards/TextGlassCard"
import HelpChoosing from "../components/offers/HelpChoosing"
import StudentReviews from "../components/cards/StudentReviews"
import VideoPopup from "../components/popups/VideoPopup"
import FormPopup from "../components/popups/FormPopup"
import VideoBox from "../components/VideoBox"
import { STRAPI_API } from "../consts/api"
import Layout from "../components/Layout"
import {SEO} from "../components/seo";

const Family = ({ data, location }) => {
  const { strapiFamilyPage, strapiStudentReview } = data

  const [popUpVideo, setPopUpVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("")

  const [formPopup, setFormPopup] = useState(false)

  const mapTextCard = data => {
    return [
      {
        title: data.item1.title,
        subtitle: data.item1.subtitle,
      },
      {
        title: data.item2.title,
        subtitle: data.item2.subtitle,
      },
      {
        title: data.item3.title,
        subtitle: data.item3.subtitle,
      },
    ]
  }

  return (
    <div>
      <SEO title={strapiFamilyPage?.titleDesc?.pageTitle} description={strapiFamilyPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="familyPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          {
            // MAIN OFFER
            strapiFamilyPage.offer && (
              <DoubleBlockHead
                setFormPopup={setFormPopup}
                data={strapiFamilyPage.offer}
              />
            )
          }

          <Title
            classAdd="m140"
            value={[
              ["red", "Как "],
              ["dark", "работает система продвижения \n"],
              ["dark", "Pioneer DJ Family"],
            ]}
            subTitle="Не знаешь, как начать карьеру в электронной музыке? Запишись на курс, в который входит помощь в продвижении"
          />

          {
            // TEXT CARDS
            strapiFamilyPage.howWork && (
              <TextGlassCards
                classAdd="textCard--two-one m60_40 "
                view="numbering"
                data={mapTextCard(strapiFamilyPage.howWork)}
              />
            )
          }

          {
            // VIDEO BOX
            strapiFamilyPage.Video?.url && (
              <div className="m60_40">
                <VideoBox
                  videSrc={STRAPI_API + strapiFamilyPage.Video.url}
                  coverImg={
                    strapiFamilyPage?.videoCover?.url
                      ? STRAPI_API + strapiFamilyPage.videoCover.url
                      : null
                  }
                  setVideoSrc={setVideoSrc}
                  setPopUpVideo={setPopUpVideo}
                />
              </div>
            )
          }

          {
            // STUDENT REVIEWS
            strapiStudentReview?.StudentReview && (
              <div className="studentReviews m140">
                <Title
                  value={[
                    ["dark", "Что говорят"],
                    ["red", " студенты о школе"],
                  ]}
                  classAdd="studentReviews--title studentReviews--title"
                />

                <StudentReviews
                  data={strapiStudentReview.StudentReview}
                  setVideoSrc={setVideoSrc}
                  setPopUpVideo={setPopUpVideo}
                  classAdd={"studentReview m60_40"}
                />
              </div>
            )
          }

          {
            // OFFER WITH FORM - CHOOSING HELP
            strapiFamilyPage?.offer2 && (
              <div className="helpChoosing m140">
                <HelpChoosing data={strapiFamilyPage.offer2} />
              </div>
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popUpVideo && videoSrc && (
          <VideoPopup
            open={popUpVideo}
            setOpen={setPopUpVideo}
            videoSrc={videoSrc}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiFamilyPage {
      offer {
        button
        title
        subtitle
      }
      offer2 {
        title
        subtitle
        button
      }
      howWork {
        item1 {
          title
          subtitle
        }
        item2 {
          title
          subtitle
        }
        item3 {
          title
          subtitle
        }
      }
      videoCover {
        url
      }
      Video {
        url
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
    strapiStudentReview {
      StudentReview {
        studentName
        coverImg {
          url
        }
        video {
          url
        }
      }
    }
  }
`

export default Family
