import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import {
  SCREEN_WIDTH_DESKTOP,
  SCREEN_WIDTH_TABLET,
} from "../../consts/SizeScreens"

export default function DraggableCards(props) {
  const { children, classAdd, mobileDisable } = props
  const ref = useRef(null)

  const [dragEnable, setDragEnable] = useState({ drag: "x" })

  function draggableHandler() {
    const windowWidth = window?.visualViewport
      ? window.visualViewport.width
      : window.innerWidth
    if (SCREEN_WIDTH_DESKTOP > windowWidth) {
      if (mobileDisable && windowWidth < SCREEN_WIDTH_TABLET) setDragEnable({})
      else setDragEnable({ drag: "x" })
    } else {
      setDragEnable({})
    }
  }

  useEffect(() => {
    draggableHandler()
    window.addEventListener("resize", draggableHandler)
    return () => {
      window.removeEventListener("resize", draggableHandler)
    }
  }, [])

  return (
    <>
      <div className="container" ref={ref}>
        <motion.div
          className={classAdd ? `${classAdd} drag__cont` : "drag__cont"}
          draggable={false}
          {...dragEnable}
          dragConstraints={ref}
        >
          {children}
        </motion.div>
      </div>
    </>
  )
}
