import * as React from "react"
import { Link } from "gatsby"

export default function Title(props) {
  const { value, classAdd, valueLink, subTitle } = props

  const mapValue = value =>
    value.map((item, index) => (
      <span
        className={
          item[0] === "dark" ? "title__value-dark" : "title__value-red"
        }
        key={index}
      >
        {item[1]}
      </span>
    ))

  return (
    <div className={classAdd ? `title ${classAdd}` : "title"}>
      <div className="title__block">
        {value && (
          <div className="title__value">
            <h2>{mapValue(value)}</h2>
          </div>
        )}

        {valueLink !== undefined && (
          <Link className="title__link" to={valueLink.link}>
            {valueLink.valueLink}
          </Link>
        )}
      </div>
      {subTitle && (
        <div className="subTitle__value">
          <h2>{subTitle}</h2>
        </div>
      )}
    </div>
  )
}
